<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button :snButton="snButton" @handleChange="handleChange"></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form ref="formdata" :inline="true" :model="formdata.saveJson" class="demo-form-inline form_row" size="small" label-width="200px">
                <!-- <el-form-item label="监测点" prop="JCDMC">
                    <el-input v-model="formdata.saveJson.JCDMC" :maxlength="50" placeholder="请输入监测点" :disabled="!isEdit"></el-input>
                </el-form-item> -->
                <el-form-item label="时间" prop="FBSJ">
                    <el-date-picker v-model="formdata.saveJson.FBSJ" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" :disabled="!isEdit" placeholder="请选择时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="温度" prop="DQQW">
                    <el-input v-model="formdata.saveJson.DQQW" placeholder="请输入温度" :maxlength="50" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit">
                        <template slot="append">°C</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="湿度" prop="DQSD">
                    <el-input v-model="formdata.saveJson.DQSD" placeholder="请输入湿度" :maxlength="50" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit">
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="风向" prop="FX">
                    <el-input v-model="formdata.saveJson.FX" placeholder="请输入风向" :maxlength="50" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit">
                        <template slot="append">度</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="风力" prop="FL">
                    <el-input v-model="formdata.saveJson.FL" placeholder="请输入风力" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit">
                        <template slot="append"></template>
                    </el-input>
                </el-form-item>
                <el-form-item label="降水量" prop="JYL_24H">
                    <el-input v-model="formdata.saveJson.JYL_24H" placeholder="请输入降水量" :maxlength="50" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit">
                        <template slot="append">mm</template>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from '../../../edit-button.js';
import infoMixin from '../../../info_mixin.js';
import { mapActions } from 'vuex';
export default {
    name: "equipmentdata_meteorological_form",
    components: {
    },
    mixins: [infoMixin],
    data() {
        return {
            notNeedFile: true,
            snButton: snButton,
            // 根据承诺事项来源类型不同掉服务获取承诺事项来源列表
            cnwcsj: false,
            cnwcsj_date: '',
            formdata: {
                saveJson: {
                    FBSJ: '',
                    DQQW: 0,
                    DQSD: 0,
                    FX: 0,
                    FL: '',
                    JYL_24H: 0,
                },
                heritageId: '',
                itemID: '8020101',
                userName: '',
                userId: ''
            },
            itemID: '8020101',
        };
    },
    computed: {
    },
    watch: {
        'formdata.saveJson.cnsxlylx': function() {
        },
    },
    mounted() {
    },
    methods: {
        ...mapActions([
        ]),
    }
};
</script>